import {
  PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import {
  RootState,
} from '@root/store/Reducer'

export const initialState: EstimationForm = {
  utm_campaign: '',
  utm_medium: '',
  utm_source: '',
  residence_type: null,
  email: '',
  terms_and_conditions: false,
  phoneNumber: null,
  startDate: null,
  startDateRadio: null,
  capital_furniture: null,
  amountOfClaims: null,
  contractTerminatedInLast3Years: null,
  accommodationCovered: null,
  facilities: [],
  housing_surface: '',
  housing_rooms: '',
  occupation_status: null,
  housing_floor_level: null,
  housing_type: null,
  address: null,
  firstName: '',
  lastName: '',
  facilitiesSurface: {},
  birthDate: '',
  familySituation: null,
  userAddress: null,
}

type SetFormField = Partial<EstimationForm>

const formSlice = createSlice({
  name: 'estimation',
  initialState,
  reducers: {
    setFormField: (state, action: PayloadAction<SetFormField>) => {
      Object.assign(state, action.payload)
    },
    reset: () => {
      return {
        ...initialState,
      }
    },
  },
})

export const getFormState = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.form
)

export const {
  setFormField,
  reset,
} = formSlice.actions

export default formSlice.reducer
